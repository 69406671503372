import * as React from 'react';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { Avatar } from '@mui/material';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';

export interface UserPopoverProps {
  onClickHandle: (language: string) => void;
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
  currentLang: string;
}

export function UserPopover({
  onClickHandle,
  anchorEl,
  onClose,
  open,
  currentLang,
}: UserPopoverProps): React.JSX.Element {
  return (
    <Popover
      elevation={2}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={onClose}
      open={open}
      slotProps={{
        paper: { sx: { width: '200px', borderRadius: '10px', marginTop: 3 } },
      }}
    >
      <MenuList
        disablePadding
        sx={{
          p: '0px',
          '& .MuiMenuItem-root': {
            borderRadius: 0,
            color: 'grey',
            fontWeight: 100,
            fontSize: '14px',
          },
        }}
      >
        {AVAILABLE_LANGUAGE_CODES.map((language, index) => (
          <MenuItem
            key={language.code}
            divider={index !== AVAILABLE_LANGUAGE_CODES.length - 1}
            selected={language.code === currentLang}
            sx={{ p: 1, pt: 1.5, pb: 1.5 }}
            className="text-gray-500"
            onClick={(event) => onClickHandle(language.code)}
          >
            <ListItemIcon sx={{ marginLeft: 1 }}>
              <Avatar
                sx={{
                  cursor: 'pointer',
                  height: '25px',
                  width: '25px',
                  backgroundColor: 'white',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                {language.code}
              </Avatar>
            </ListItemIcon>
            {language.language}
          </MenuItem>
        ))}
      </MenuList>
      <Divider />
    </Popover>
  );
}
