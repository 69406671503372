import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { Button, IWarrantyLogoIcon } from '@rabbit/elements/shared-components';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import {
  useAppInfo,
  getUserPermissions,
  isAuthPartner,
  usePopover,
} from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import {
  Permissions,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import ModalNewRegistration from '../../organisms/ModalNewRegistration/ModalNewRegistration';
import ModalNewClaim from '../../organisms/ModalNewClaim/ModalNewClaim';
import ModalNewClaimVehicle from '../../organisms/ModalNewClaimVehicle/ModalNewClaimVehicle';
import { ConfigContext } from '@rabbit/config/context';
import { Avatar } from '@mui/material';
import { UserPopover } from '@rabbit/sage/components/molecules/NavBar/user-popover.tsx';
import { useManageSageProfile } from '@rabbit/bizproc/react';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';

/* eslint-disable-next-line */
export interface NavBarProps {}

const dynamicStyles = {
  header:
    'px-6 flex justify-between bg-white w-full border-b-[1px] z-20 relative',
  linkBase: 'text-gray-900 font-nunito text-base no-underline',
  linkIsActive: 'font-bold',
};

export function NavBar(props: NavBarProps) {
  const appInfo = useAppInfo();
  const { t, i18n } = useTranslation();
  const contextValues = useContext(UserContext);
  const { config } = useContext(ConfigContext);
  const handleSignOut = contextValues?.handleSignout;
  const user = contextValues && contextValues.user ? contextValues.user : null;

  const tenantLink = t('tenantLink');
  const tenantConfigType = t('tenantConfigType');
  const CFG_TENANT_IS_MANUFACTURER = t('CFG_TENANT_IS_MANUFACTURER') === 'true';

  const userPermissions = getUserPermissions(tenantLink, contextValues);
  // console.log('KEEP for debug: contextValues', contextValues);
  //console.log('KEEP for debug: userPermissions', userPermissions);
  const [newClaimModalOpen, setNewClaimModalOpen] = useState(false);
  const [newRegistrationModalOpen, setNewRegistrationModalOpen] =
    useState(false);

  const userPopover = usePopover<HTMLDivElement>();
  const getNavigation = () => {
    const navItems = [
      {
        label: t('general.dashboard'),
        path: SAGE_ROUTE_NAME.DASHBOARD,
        permission: [Permissions.Owner], //[Permissions.DashboardView, Permissions.Owner]
        includeTenant: ['DEMO'] as string[], // empty array means show to no one
      },
      {
        label: t('general.registrations'),
        path: SAGE_ROUTE_NAME.REGISTRATIONS,
        permission: [
          Permissions.RegistrationsView,
          Permissions.RegistrationsEdit,
          Permissions.Owner,
        ],
        excludeTenant: [],
      },
      {
        label: t('general.warranties'),
        path: SAGE_ROUTE_NAME.WARRANTY_OVERVIEW,
        //Warranties page is not visible if the tenant is a manufacturer -VP
        permission: !CFG_TENANT_IS_MANUFACTURER ? [Permissions.Owner] : [],
        excludeTenant: [],
      },
      {
        label: t('general.claims'),
        path: SAGE_ROUTE_NAME.CLAIMS,
        permission: [
          Permissions.CasesView,
          Permissions.CasesEdit,
          Permissions.Owner,
        ],
        excludeTenant: [],
      },
      {
        label: t('general.manage'),
        path: SAGE_ROUTE_NAME.MANAGE,
        permission: [Permissions.Owner],
        excludeTenant: [],
      },
      {
        label: t('general.myClaims'),
        path: SAGE_ROUTE_NAME.MY_CLAIMS,
        permission: [
          Permissions.CasesView,
          Permissions.CasesEdit,
          Permissions.Owner,
        ],
        excludeTenant: [
          'WARRANTYIRELAND',
          'NUCOVER',
          'PINNACLEWARRANTIES',
          'CHARLIEOVEN',
          'UNIONAUDIO',
        ],
      },
    ];

    const filteredNavItems = navItems
      .filter((item) => {
        if (item.excludeTenant?.includes(tenantLink)) return false;
        if (
          item.includeTenant &&
          Array.isArray(item.includeTenant) &&
          !item.includeTenant.includes(tenantLink)
        )
          return false;

        if (Array.isArray(item.permission)) {
          return item.permission.some((permission) => {
            return userPermissions.includes(permission);
          });
        } else {
          return userPermissions.includes(item.permission);
        }
      })
      .filter((navItem) => {
        return config.NAVIGATION.RESTRICTED_PATHS.indexOf(navItem.path) === -1;
      });

    return filteredNavItems;
  };

  const navigations = getNavigation();
  let currentLang = t('CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE');
  let langAvatar: string | undefined = '';
  let switchLanguage = (languageCode: string) => {};
  if (user) {
    const { identity, updateLanguageCode } = useManageSageProfile();
    currentLang =
      user &&
      identity?.data?.identity?.languageCode &&
      identity?.data?.identity?.languageCode !== ''
        ? identity?.data?.identity?.languageCode
        : t('CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE');

    switchLanguage = async (languageCode: string) => {
      userPopover.handleClose();
      await updateLanguageCode(languageCode);
    };
    (async () => {
      if (
        identity?.data?.identity?.languageCode &&
        identity?.data?.identity?.languageCode !== t('localLanguage')
      ) {
        await i18n.changeLanguage(identity?.data?.identity?.languageCode);
      }
    })();
  }
  const ctaModalButtons = [
    {
      label: t('general.newRegistration'),
      callback: () => setNewRegistrationModalOpen(true),
      permission: [Permissions.RegistrationsEdit, Permissions.Owner],
      include: t('CFG_NAVBAR_NEW_REGISTRATION') === 'true',
    },
    {
      label: t('general.newClaim'),
      callback: () => setNewClaimModalOpen(true),
      permission: [Permissions.CasesEdit, Permissions.Owner],
      include: t('CFG_NAVBAR_NEW_CLAIM') === 'true',
    },
  ];

  return (
    <header className={dynamicStyles.header}>
      <div className="flex items-center justify-start gap-[40px] py-4">
        <NavLink
          to={navigations.length > 0 ? navigations[0].path : '/'}
          className="flex no-underline"
        >
          <IWarrantyLogoIcon className="mr-4" />
          <p className="font-caudex text-2xl text-gray-900">iWarranty</p>
        </NavLink>
        {user &&
          navigations.map(
            (item) =>
              item && (
                <NavLink
                  key={item.label}
                  to={item.path}
                  className={dynamicStyles.linkBase}
                >
                  {({ isActive }) => {
                    return (
                      <span
                        className={classNames(``, {
                          [`${dynamicStyles.linkIsActive}`]: isActive,
                        })}
                      >
                        {item.label}
                      </span>
                    );
                  }}
                </NavLink>
              )
          )}
      </div>
      {user && (
        <div className="my-auto flex h-fit items-center border-l border-gray-200 pl-5">
          {ctaModalButtons.map((button) => {
            if (
              button.include &&
              button.permission.some((permission) =>
                userPermissions.includes(permission)
              )
            ) {
              return (
                <Button
                  key={button.label}
                  className="mr-4 h-[34px] px-10"
                  kind="primary"
                  onClick={button.callback}
                >
                  {button.label}
                </Button>
              );
            }
          })}
          {user && currentLang && (
            <>
              <Avatar
                onClick={userPopover.handleOpen}
                ref={userPopover.anchorRef}
                sx={{
                  cursor: 'pointer',
                  height: '35px',
                  width: '35px',
                  backgroundColor: 'white',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                {currentLang}
              </Avatar>
              <UserPopover
                onClickHandle={switchLanguage}
                anchorEl={userPopover.anchorRef.current}
                onClose={userPopover.handleClose}
                open={userPopover.open}
                currentLang={currentLang}
              />
            </>
          )}
          <div className="group relative group-hover:block">
            <div className="group-hover ml-5 flex cursor-pointer items-center">
              <img
                src={appInfo.logo}
                alt={appInfo.name}
                className="mr-1 max-h-[40px] max-w-[220px]"
              />
              <ChevronDownIcon className="h-6 w-6 text-gray-900" />
            </div>
            <div className="absolute right-0 z-10 hidden w-[224px] bg-white pt-[24px] group-hover:block">
              <div className="rounded-b-xl bg-white shadow-xl">
                <div className="w-full overflow-hidden border-b border-gray-100 px-4 py-3 text-base">
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                    <span className="font-medium capitalize">
                      {user.displayName ? user.displayName : appInfo.name}
                    </span>
                  </div>
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                    <span>{user.email && user.email}</span>
                  </div>
                </div>
                <div className="border-b border-gray-100 px-4 py-3 text-base ">
                  <Link
                    to={SAGE_ROUTE_NAME.MANAGE_ACCOUNT}
                    className="text-gray-500 no-underline hover:text-gray-800"
                  >
                    <div>{t('general.myAccount')}</div>
                  </Link>
                </div>
                {userPermissions.includes(Permissions.Owner) && (
                  <div className="border-b border-gray-100 px-4 py-3 text-base">
                    <Link
                      to={SAGE_ROUTE_NAME.MY_ORGANIZATION}
                      className="text-gray-500 no-underline hover:text-gray-800"
                    >
                      <div>{t('general.myOrganization')}</div>
                    </Link>
                  </div>
                )}
                <div className="px-4 py-3 text-base">
                  <div
                    className="cursor-pointer text-gray-500 no-underline hover:text-gray-800"
                    onClick={handleSignOut}
                  >
                    {t('general.signOut')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {newClaimModalOpen &&
        (tenantConfigType === 'vehicle' ? (
          <ModalNewClaimVehicle
            handleClose={() => setNewClaimModalOpen(false)}
          />
        ) : (
          <ModalNewClaim handleClose={() => setNewClaimModalOpen(false)} />
        ))}
      {newRegistrationModalOpen && (
        <ModalNewRegistration
          handleClose={() => setNewRegistrationModalOpen(false)}
        />
      )}
    </header>
  );
}

export default NavBar;
